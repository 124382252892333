<template>
  <div class="FormInput__container">
    <o-field class="FormInput__wrapper" :variant="variant">
      <template #label>{{ label }} </template>
      <o-input
        v-model="value"
        :name="name"
        :type="type"
        autocomplete="on"
        @focus.once="handleFocus(true)"
        @blur.once="handleFocus(false)"
        @update:modal-value="emitUpdateModelValue"
      ></o-input>
    </o-field>
    <span class="FormInput__error" :class="errorClass">
      {{ !focused && !meta.valid ? errorMessage : "" }}
    </span>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useField } from "vee-validate";
export type Value = string | number;

type Props = {
  label: string;
  name: string;
  type?: "email" | "text";
  initialValue?: Value;
  errorColor?: "NORMAL" | "LIGHT";
};
const props = withDefaults(defineProps<Props>(), {
  errorColor: "NORMAL",
  type: "text",
  initialValue: "",
});
const focused = ref(false);
const { value, errorMessage, meta } = useField(() => props.name, undefined, {
  initialValue: props.initialValue,
});

const handleFocus = (value: boolean) => {
  focused.value = value;
};

const emit = defineEmits(["update:modelValue"]);
const emitUpdateModelValue = (value: Value) => {
  emit("update:modelValue", value);
};

const errorClass = computed(() => {
  if (props.errorColor === "LIGHT") {
    return "light";
  }
  return "";
});

const variant = computed(() => {
  if (meta.dirty && !focused.value && !meta.valid) {
    return "danger";
  }
  if (meta.dirty && meta.valid) {
    return "success";
  }
  return "primary";
});

defineExpose({ value });
</script>
<style scoped lang="scss">
.FormInput {
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__error {
    @include body-s;
    color: $color-basic-error;
    margin: 4px 0;
    &.light {
      color: $color-basic-error-light;
    }
  }
}
</style>
